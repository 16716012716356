import React from "react";
import Card from "@components/homePageComponents/whatPeopleAreSayingSection/cards/Card";

export default function Cards({ cards }) {
  return (
    <>
      {cards.map((card) => (
        <Card key={card.id} card={card} />
      ))}
    </>
  );
}
