import React from "react";
import Slider from "react-slick";
import { TestimonialsConfig } from "@utility/constants";
import Card from "@components/homePageComponents/whatPeopleAreSayingSection/cards/Card";

// Slider has to be a class component in order to use the responsive prop apparently.
class CardSlider extends React.Component {
  render() {
    const settings = {
      arrows: false,
      centerMode: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "50px",
          },
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "100px",
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "190px",
          },
        },
      ],
    };

    function Cards() {
      return TestimonialsConfig.map((card) => (
        <Card key={card.id} card={card} />
      ));
    }

    return (
      <div className="lg:hidden overflow-x-hidden w-full flex justify-center relative -top-10 z-20">
        <div className="w-full h-full">
          <Slider {...settings}>{Cards()}</Slider>
        </div>
      </div>
    );
  }
}

export default CardSlider;
