import React from "react";
import Navbar from "@components/homePageComponents/navbar/Navbar";
import DownloadTheAppButtons from "@components/buttons/DownloadTheAppButtons";

export default function Header() {
  return (
    <header
      className="bg-gray-300 flex flex-col w-full relative items-center lg:min-h-700  overflow-hidden"
      style={{ backgroundColor: "#21252A" }}
    >
      <HeaderShadow />
      <HeaderPhonesContainer />
      <HeaderMiddleCircle />
      <HeaderPurpleCircle />
      <HeaderPurpleCross />
      <HeaderBlueCircle />
      <HeaderSmallGreyCircle />
      <Navbar />
      <HeaderContentContainer />
    </header>
  );
}

function HeaderBackgroundSquare() {
  return (
    <div className="absolute left-0 top-0 ">
      <img
        className="h-28 w-28 relative object-cover"
        src="/images/headersquare.png"
        alt=""
        height="112"
        width="112"
      />
    </div>
  );
}
// specific to the content container

function HeaderContentContainer() {
  return (
    <div
      className=" lg:mt-10 lg:ml-24 relative w-full lg:max-w-1500 pt-6 flex mb-10 lg:mb-0"
      style={{ zIndex: "100" }}
    >
      <HeaderBackgroundSquare />
      <div className="flex flex-col lg:flex-row">
        <div className="pt-1 w-full phoneBreakpoint:w-2/3 laptop:w-1/2 z-10 px-4">
          <div className="flex flex-col">
            <span className="text-white text-4xl lg:text-5xl text-left">
              The <span className="text-headerBlueText ">real moments,</span>
            </span>
            <span className="text-white text-4xl lg:text-5xl text-left">
              not the highlight reel
            </span>
          </div>
          <p className="text-white text-lg lg:text-xl mt-8 text-left w-full md:w-2/3 lg:w-full ">
            People get authentic when there are only seconds to share. When
            Snacktime sends a notification, you post your moment.
          </p>
          <DownloadTheAppButtons size="large" />
        </div>
      </div>
    </div>
  );
}

function HeaderPhones() {
  return (
    <div className="rotate-[20deg] relative left-[550px] lg:left-[180px] flex h-full">
      {/* 1st row */}
      <div className="relative bottom-[50px]">
        <div className="relative left-8">
          <img
            src="/images/headerPhoneImages/phone0.webp"
            alt=""
            className="h-[610px] w-[345px] min-w-[345px]"
            width="345"
            height="610"
          />
        </div>
        <div className="relative w-full left-8 bottom-10">
          <img
            src="/images/headerPhoneImages/phone1.webp"
            alt=""
            className="h-[610px] w-[345px] min-w-[345px]"
            width="345"
            height="610"
          />
        </div>
      </div>
      {/* 2nd row */}
      <div className="bottom-[500px] relative right-8">
        <div className="relative w-full left-8 flex top-6">
          <img
            src="/images/headerPhoneImages/phone2.webp"
            alt=""
            className="h-[610px] w-[345px] min-w-[345px]"
            width="345"
            height="610"
          />
        </div>
        <div className="relative left-8 bottom-3">
          <img
            src="/images/headerPhoneImages/phone3.webp"
            alt=""
            className="h-[610px] w-[345px] min-w-[345px]"
            width="345"
            height="610"
          />
        </div>
        <div className="relative left-8 bottom-12">
          <img
            src="/images/headerPhoneImages/phone4.webp"
            alt=""
            className="h-[610px] w-[345px] min-w-[345px]"
            width="345"
            height="610"
          />
        </div>
      </div>
      {/* 3rd row */}
      <div className="w-auto bottom-[400px] right-[63px] flex-col relative">
        <div className="relative left-8">
          <img
            src="/images/headerPhoneImages/phone2.webp"
            alt=""
            className="h-[610px] w-[345px] min-w-[345px]"
            width="345"
            height="610"
          />
        </div>
        <div className="relative left-8 bottom-9">
          <img
            src="/images/headerPhoneImages/phone5.webp"
            alt=""
            className="h-[610px] w-[345px] min-w-[345px]"
            width="345"
            height="610"
          />
        </div>
      </div>
    </div>
  );
}
function HeaderPhonesContainer() {
  return (
    <div className="hidden md:flex w-full justify-end absolute h-full right-0 z-10 overflow-hidden ">
      <HeaderPhones />
    </div>
  );
}

function HeaderMiddleCircle() {
  return (
    <div className="hidden lg:block absolute h-full w-full">
      <img
        src="/images/headercircle-min.png"
        alt=""
        className="relative top-1/2 left-1/2 transform -translate-x-1/3 -translate-y-1/2"
        height="540"
        width="540"
      />
    </div>
  );
}

function HeaderPurpleCircle() {
  return (
    <div className="hidden lg:block absolute left-0 -bottom-5">
      <img
        className="h-80 w-80 relative bottom-0 "
        src="/images/headerpurplecircle.png"
        alt=""
        height="320"
        width="320"
      />
    </div>
  );
}

function HeaderPurpleCross() {
  return (
    <div className="hidden lg:block absolute h-full w-full ">
      <img
        className="absolute bottom-20 left-1/3 h-8 w-8 transform -translate-x-1/3 -translate-y-1/2 object-contain"
        src="/images/headercross.png"
        alt=""
        height="32"
        width="32"
      />
    </div>
  );
}

function HeaderBlueCircle() {
  return (
    <img
      className="hidden lg:block absolute w-6 h-6 left-8 top-[300px]"
      src="/images/headerbluecircle.png"
      alt=""
      height="24"
      width="24"
    />
  );
}
function HeaderShadow() {
  return (
    <img
      src="/images/headershadow.png"
      className=" hidden lg:block absolute z-20 w-full h-64"
      alt=""
      height="120"
      width="100"
    />
  );
}

function HeaderSmallGreyCircle() {
  return (
    <div className="absolute top-40 right-10 lg:right-2/3 transform -translate-x-1/2 -translate-y-1/2">
      <img
        src="/images/headersmallgreycircle.png"
        alt=""
        height="24"
        width="24"
      />
    </div>
  );
}
