import React from "react";

export default function GooglePlayStoreButton({ size }) {
  const buttonConfig = {
    // Sizes
    small: "h-11 max-h-11 w-[134px] max-w-[134px]",
    medium: "h-12 max-h-12 w-[144px] max-w-[144px]",
    large: "h-13 max-h-13 w-[150px] max-w-[150px]",
  };

  return (
    <a
      href={process.env.NEXT_PUBLIC_SNACKTIME_GOOGLE_PLAY_STORE_URL}
      target="_blank"
      rel="noreferrer"
    >
      <img
        src="/images/google.webp"
        className={`${buttonConfig[size]} cursor-pointer border border-gray-100 rounded-md`}
        alt=""
      />
    </a>
  );
}
