import React from "react";

export default function Navbar() {
  return (
    <div
      className="flex justify-start items-center w-full py-10 lg:py-8 lg:pl-8"
      style={{ zIndex: "100" }}
    >
      <img
        src="/images/Logo-white.png"
        alt="Snacktime"
        height="85"
        width="280"
      />
    </div>
  );
}
