import React from "react";

export default function DetailsSection() {
  return (
    <section className="w-full justify-center lg:min-h-400 flex md:mb-32">
      <DetailsContentContainer />
    </section>
  );
}

function DetailsContentContainer() {
  return (
    <div className=" w-full min-w-1200 flex flex-col phoneBreakpoint:flex-row lg:max-w-1200 relative bottom-12 h-full">
      <DetailsImageContainer />
      <DetailsCardsContainer />
    </div>
  );
}

function DetailsImageContainer() {
  return (
    <div className="w-full flex justify-center md:justify-center lg:justify-start mb-4 lg:mb-0 p-4 phoneBreakpoint:p-0 lg:py-0">
      <div className="lg:w-3/4 relative phoneBreakpoint:left-10 lg:right-0 lg:p-0 flex w-full justify-center">
        <img
          src="/images/detailsphone.webp"
          className=""
          alt="Snacktime on a phone"
          height="443"
          width="443"
        />
      </div>
    </div>
  );
}

function DetailsCardsContainer() {
  return (
    <div className="z-20 phoneBreakpoint:right-10 lg:left-0 relative w-full flex flex-col px-4 lg:py-0 lg:pt-0 justify-center lg:justify-start ">
      <DetailsCard
        img="/images/bell-icon_2.png"
        alt="bell icon"
        title="It’s Snacktime!"
        body="Once a day you’ll get an alert."
      />
      <DetailsCard
        img="/images/cloud-icon.png"
        alt="cloud icon"
        title="Find something interesting"
        body="You have just seconds to take a photo or video."
      />
      <DetailsCard
        img="/images/phone-icon.png"
        alt="phone icon"
        title="Share it on Snacktime"
        body="Snacktime is 100% original content. Everyone shares the moment they’re in."
      />
    </div>
  );
}

function DetailsCard({ img, alt, title, body }) {
  return (
    <div className="w-full max-w-600 lg:max-w-full h-32 phoneBreakpoint:h-24 py-4 max-h-32 mb-4 md:mb-6 flex rounded-md shadow-cardShadow bg-white">
      <div className=" px-6 flex items-center justify-center">
        <img src={img} alt={alt} height="50" width="50" />
      </div>

      <div className="w-3/4 flex flex-col pl-4 py-2 h-full px-2 justify-center">
        <span className=" text-lg md:text-xl lg:text-2xl text-purpleText mb-1 lg:mb-2">
          {title}
        </span>
        <p className="text-gray-500 500:text-[12px] lg:text-[14px]">{body}</p>
      </div>
    </div>
  );
}
