import React from "react";
import Head from "next/head";
import Header from "@components/homePageComponents/headerSection/Header";
import Banner from "@components/homePageComponents/howItWorksSection/Banner";
import DetailsSection from "@components/homePageComponents/detailsSection/DetailsMain";
import WhatPeopleAreSayingSection from "@components/homePageComponents/whatPeopleAreSayingSection/Section";
import PhoneSection from "@components/homePageComponents/phoneSection/Section";
import Footer from "@components/homePageComponents/footer/Footer";
import Slider from "@components/homePageComponents/slider/Slider";
import Layout from "@components/Layout";

export default function Index() {
  return (
    <Layout title="Snacktime - photos, videos, and luck">
      <Head>
        <meta
          property="og:url"
          content={process.env.NEXT_PUBLIC_SNACKTIME_PUBLIC_WEB_URL}
          key="ogurl"
        />
        <meta property="og:site_name" content="Snacktime" key="ogsitename" />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_SNACKTIME_PUBLIC_WEB_URL}/Snacktime-app-icon-1024x1024.png`}
          key="ogimage"
        />
        <meta property="og:title" content="Snacktime" key="ogsitename" />
        <meta property="og:type" content="website" key="ogtype" />
        <meta
          property="og:description"
          content="When we send a notification you have seconds to share your moment. Snacktime is the real moments, not the highlight reel."
          key="ogdesc"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:site"
          content={process.env.NEXT_PUBLIC_SNACKTIME_TWITTER_USERNAME}
        />
        <meta name="twitter:title" content="Snacktime" />
        <meta name="twitter:description" content="Photos, videos, and luck" />
        <meta
          name="twitter:image"
          content={`${process.env.NEXT_PUBLIC_SNACKTIME_PUBLIC_WEB_URL}/Snacktime-app-icon-1024x1024.png`}
        />
        <meta
          property="twitter:image:alt"
          content="Snacktime - photos, videos, and luck"
        />
        <meta
          property="fb:app_id"
          content={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
        />
      </Head>
      <Header />
      <main>
        <Banner />
        <DetailsSection />
        <WhatPeopleAreSayingSection />
        <Slider />
        <PhoneSection />
      </main>
      <Footer />
    </Layout>
  );
}
