import React from "react";

export default function Banner() {
  return (
    <div
      className="relative flex justify-center items-center w-full overflow-x-hidden min-h-[230px]  overflow-hidden flex items-center"
      style={{
        background: "#AC21CC",
        backgroundImage: "linear-gradient(100deg, #AC21CC 0%, #5106C7 74%)",
      }}
    >
      <BannerCamera />
      <MobileBannerCamera />
      <BannerContent />
    </div>
  );
}
function BannerCamera() {
  return (
    <div className="hidden lg:flex absolute right-0 h-full bottom-0 justify-end">
      <img
        src="/images/camera.png"
        className="relative"
        alt="camera"
        width="270"
        height="230"
      />
    </div>
  );
}
function MobileBannerCamera() {
  return (
    <div className="lg:hidden absolute right-0 h-full top-20 flex justify-end">
      <div className="relative bottom-5">
        <img
          className="w-36"
          src="/images/mobilecamera.png"
          alt="camera"
          width="144"
          height="173"
        />
      </div>
    </div>
  );
}
function BannerContent() {
  return (
    <div className="w-full max-w-1200 flex tems-center justify-center lg:flex-row z-10 mb-10 px-2 lg:mb-0 ">
      <span className="text-white text-6xl text-center">How it works</span>
    </div>
  );
}
