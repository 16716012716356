import React from "react";
import CardsContainer from "@components/homePageComponents/whatPeopleAreSayingSection/cards/CardsContainer";

export default function Section() {
  return (
    <section className="flex h-[450px] w-full justify-end bg-headerBackground relative mt-10 lg:mt-0 pt-10 items-end">
      <LadyImage />
      <LeftPurpleCircle />
      <RightCircle />
      <DekstopViewContentContainer />
      <MobileViewContentContainer />
      <PurpleCross />
      <PinkSparkleImage />
      <SmallPurpleCircle />
      <SmallBlueCircle />
      <SmallGreyX />
    </section>
  );
}
function LeftPurpleCircle() {
  return (
    <div className="absolute top-1/2 left-5 lg:left-40 transform -translate-x-1/3 -translate-y-1/2">
      <img
        src="/images/lady-purple-circle.png"
        alt=""
        height="371"
        width="371"
      />
    </div>
  );
}

function LadyImage() {
  return (
    <img
      src="/images/lady.webp"
      alt=""
      className="absolute -left-[10px] lg:left-40 bottom-0 z-20"
      height="500"
      width="294"
    />
  );
}

function RightCircle() {
  return (
    <div className="absolute flex justify-end z-10 w-full h-full">
      <img
        src="/images/rightcircle.png"
        className="absolute max-h-full right-0"
        alt=""
        height="450"
        width="384"
      />
    </div>
  );
}

function PinkSparkleImage() {
  return (
    <div className="hidden lg:block absolute right-80 z-10 max-h-full top-[20px] ">
      <img src="/images/pink-sparkle.png" height="42" width="52" alt="" />
    </div>
  );
}

function SmallPurpleCircle() {
  return (
    <div className="hidden lg:block absolute right-40 z-10 max-h-full top-1/3">
      <img
        src="/images/small-purple-circle.png"
        alt=""
        height="21"
        width="21"
      />
    </div>
  );
}

function PurpleCross() {
  return (
    <div className="lg:hidden absolute bottom-10 right-10 z-10">
      <img src="/images/headercross.png" height="24" width="24" alt="" />
    </div>
  );
}

function SmallBlueCircle() {
  return (
    <div className="hidden lg:block  absolute left-20 z-10 max-h-full bottom-10">
      <img src="/images/small-blue-circle.png" alt="" height="20" width="20" />
    </div>
  );
}

function SmallGreyX() {
  return (
    <div className="hidden lg:block absolute right-20 z-10 max-h-full top-10">
      <img src="/images/small-grey-x.png" alt="" height="20" width="20" />
    </div>
  );
}
function Quotes() {
  return (
    <img
      src="/images/quotes.png"
      alt="quotes"
      height="108"
      width="146"
      className="absolute -translate-y-1/2 transform -left-14 z-10 "
    />
  );
}
function DekstopViewContentContainer() {
  return (
    <div className="hidden lg:flex w-2/3 h-full">
      <div className="flex flex-col w-full ">
        <div className="h-1/2 flex-1 flex relative items-center ">
          <span className="text-white text-6xl z-20">
            What people are saying
          </span>
          <Quotes />
        </div>
        <div className="h-1/2 w-full relative flex max-h-full">
          <CardsContainer />
        </div>
      </div>
    </div>
  );
}

function MobileViewContentContainer() {
  return (
    <div className="lg:hidden h-full w-1/2 ">
      <div className="flex flex-col h-full justify-center">
        <div className=" flex relative items-center pl-2">
          <span className="text-white text-6xl text-center z-20">
            What people are saying
          </span>
        </div>
      </div>
    </div>
  );
}
