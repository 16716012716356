// jitter explanation: https://aws.amazon.com/blogs/architecture/exponential-backoff-and-jitter/
export const TestimonialsConfig = [
  {
    id: 0,
    // img: "/images/cardpic2.png",
    name: "@megan",
    title: "",
    body: "What you post is what is happening at that exact moment in your life and that’s what I love most.",
  },
  {
    // img: "/images/cardpic3.png",
    id: 1,
    name: "@sam",
    title: "",
    body: "The excitement I feel when I get a Snacktime notification is truly unparalleled.",
  },
  {
    id: 2,
    // img: "/images/cardpic1.png",
    name: "@andrew",
    title: "",
    body: "My favorite app!",
  },
];

export const BackOffDefaultOptions = {
  numOfAttempts: 5,
  jitter: "full",
};

export const PostFormat = Object.freeze({
  GRID: "GRID",
  FULL: "FULL",
  FEED: "FEED",
});

export const HubChannel = Object.freeze({
  POST_CHANNEL: "POST_CHANNEL",
  COMMENT_CHANNEL: "COMMENT_CHANNEL",
  POST_WINDOW_CHANNEL: "POST_WINDOW_CHANNEL",
  AUTHENTICATION_CHANNEL: "auth",
  AUTHENTICATION_RESPONSE_CHANNEL: "AUTHENTICATION_RESPONSE_CHANNEL",
  SEARCH_CHANNEL: "SEARCH_CHANNEL",
  FEED_EVENT_CHANNEL: "FEED_EVENT_CHANNEL",
  ALERT_CHANNEL: "ALERT_CHANNEL",
  PERMISSION_CHANNEL: "PERMISSION_CHANNEL",
  UI_CHANNEL: "UI_CHANNEL",
  ACCOUNT_CHANNEL: "ACCOUNT_CHANNEL",
});

export const UIEvent = Object.freeze({
  BOTTOM_TAB_BAR_LAYOUT: "BOTTOM_TAB_BAR_LAYOUT",
  PROGRESS: "PROGRESS",
});

export const AuthenticationEvent = Object.freeze({
  AUTH_CHALLENGE_ANSWER: "AUTH_CHALLENGE_ANSWER",
});

export const AuthenticationType = Object.freeze({
  SIGNIN: "SIGNIN",
  SIGNUP: "SIGNUP",
});

export const AuthenticationUsernameType = Object.freeze({
  EMAIL: "EMAIL",
  PHONE: "PHONE",
  USERNAME: "USERNAME",
});

export const PushNotificationEvent = Object.freeze({
  POST_WINDOW_OPEN: "POST_WINDOW_OPEN",
  POST_WINDOW_CLOSE: "POST_WINDOW_CLOSE",
  POST_WINDOW_UNSCHEDULE: "POST_WINDOW_UNSCHEDULE",
  ALERT_COMMENT_LIKE: "ALERT_COMMENT_LIKE",
  ALERT_POST: "ALERT_POST",
  ALERT_FOLLOW: "ALERT_FOLLOW",
  ALERT_POST_COMMENT: "ALERT_POST_COMMENT",
  ALERT_POST_COMMENT_REPLY: "ALERT_POST_COMMENT_REPLY",
  ALERT_POST_SHARE: "ALERT_POST_SHARE",
  ALERT_POST_LIKE: "ALERT_POST_LIKE",
  ALERT_POST_MENTION: "ALERT_POST_MENTION",
  ALERT_COMMENT_MENTION: "ALERT_COMMENT_MENTION",
  ALERT_PROFILE_MENTION: "ALERT_PROFILE_MENTION",
  ACCOUNT_DEACTIVATION: "ACCOUNT_DEACTIVATION",
});

export const PostEvent = Object.freeze({
  OPEN_TITLE_INPUT: "OPEN_TITLE_INPUT",
});

export const PostWindowEvent = Object.freeze({
  POST_WINDOW_OPEN: "POST_WINDOW_OPEN",
  POST_WINDOW_REOPEN: "POST_WINDOW_REOPEN",
  NEW_POST_WINDOW: "NEW_POST_WINDOW",
  POST_WINDOW_TICK: "POST_WINDOW_TICK",
  POST_WINDOW_CLOSE: "POST_WINDOW_CLOSE",
  INVALID_POST_WINDOW: "INVALID_POST_WINDOW",
  CHECK_FOR_ACTIVE_POST_WINDOW: "CHECK_FOR_ACTIVE_POST_WINDOW",
});

export const CommentEvent = Object.freeze({
  COMMENT_ADDED: "COMMENT_ADDED",
  COMMENT_DELETED: "COMMENT_DELETED",
  COMMENT_LIKED: "COMMENT_LIKED",
});

export const AlertEvent = Object.freeze({
  REFETCH: "REFETCH",
});

export const AlertType = Object.freeze({
  POST: "POST",
  COMMENT_LIKE: "COMMENT_LIKE",
  FOLLOW: "FOLLOW",
  POST_COMMENT: "POST_COMMENT",
  POST_COMMENT_REPLY: "POST_COMMENT_REPLY",
  POST_SHARE: "POST_SHARE",
  POST_LIKE: "POST_LIKE",
  POST_MENTION: "POST_MENTION",
  COMMENT_MENTION: "COMMENT_MENTION",
  PROFILE_MENTION: "PROFILE_MENTION",
});

export const SearchEvent = Object.freeze({
  SEARCH_CANCEL: "SEARCH_CANCEL",
  SEARCH_INPUT_FOCUS: "SEARCH_INPUT_FOCUS",
  SEARCH_INPUT_BLUR: "SEARCH_INPUT_BLUR",
  SEARCH_INPUT_CHANGE: "SEARCH_INPUT_CHANGE",
});

export const FeedEvent = Object.freeze({
  SET_FEED_TYPE: "SET_FEED_TYPE",
  SCROLL_TO_TOP: "SCROLL_TO_TOP",
  SET_SCROLL_ENABLED: "SET_SCROLL_ENABLED",
  SHOW_FEED_TYPE: "SHOW_FEED_TYPE",
  REFETCH_FOR_YOU_FEED: "REFETCH_FOR_YOU_FEED",
  REFETCH_FOLLOW_FEED: "REFETCH_FOLLOW_FEED",
});

export const FeedType = Object.freeze({
  FOLLOW: "FOLLOW",
  FORYOU: "FORYOU",
  USER: "USER",
  HASHTAG: "HASHTAG",
  LIKED: "LIKED",
  ADMIN: "ADMIN",
});

export const PostReportReasonCode = Object.freeze({
  SPAM: "SPAM",
  INAPPROPRIATE: "INAPPROPRIATE",
});

export const CommentReportReasonCode = Object.freeze({
  SPAM: "SPAM",
  INAPPROPRIATE: "INAPPROPRIATE",
});

export const UserReportReasonCode = Object.freeze({
  HARASSMENT: "HARRASSMENT",
  SPAM: "SPAM",
  OTHER: "OTHER",
});

export const ContentType = Object.freeze({
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  DRAWING: "DRAWING",
  TEXT: "TEXT",
  NONE: "NONE",
});

export const ContentMimeType = Object.freeze({
  IMAGE: "image/jpeg",
  VIDEO: "video/quicktime",
  TEXT: "text/plain",
});

export const PostResponseType = Object.freeze({
  ARTIFACT: "ARTIFACT",
  LEGENDARY: "LEGENDARY",
  EPIC: "EPIC",
  RARE: "RARE",
  UNCOMMON: "UNCOMMON",
  COMMON: "COMMON",
});

export const PermissionEvent = Object.freeze({
  PUSH_NOTIFICATION_RESPONSE: "PUSH_NOTIFICATION_RESPONSE",
  PUSH_NOTIFICATIONS_PERMISSION_PROMPT_MODAL_DECLINED:
    "PUSH_NOTIFICATIONS_PERMISSION_PROMPT_MODAL_DECLINED",
});

export const AccountEvent = Object.freeze({
  ACCOUNT_DEACTIVATION: "ACCOUNT_DEACTIVATION",
});

export const AvatarSize = Object.freeze({
  XSMALL: "XSMALL",
  SMALL: "SMALL",
  MEDIUM: "MEDIUM",
  LARGE: "LARGE",
  XLARGE: "XLARGE",
  FULL: "FULL",
});

export const AvatarImageStyles = Object.freeze({
  XSMALL: {
    layoutWidth: 20,
    layoutHeight: 20,
    width: 20,
    height: 20,
    borderRadius: 10,
  },
  SMALL: {
    layoutWidth: 40,
    layoutHeight: 40,
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  MEDIUM: {
    layoutWidth: 50,
    layoutHeight: 50,
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  LARGE: {
    layoutWidth: 100,
    layoutHeight: 100,
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  XLARGE: {
    layoutWidth: 150,
    layoutHeight: 150,
    width: 150,
    height: 150,
    borderRadius: 75,
  },
  FULL: {
    flex: 1,
    height: undefined,
    width: undefined,
    layoutWidth: undefined,
    layoutHeight: undefined,
  },
});

export const CameraIcon = Object.freeze({
  CAMERA_SHUTTER: "ellipse",
  VIDEO_SHUTTER_ON: "square",
  VIDEO_SHUTTER_OFF: "ellipse",
  CAMERA_TOGGLE: "camera",
  VIDEO_TOGGLE: "videocam",
  FLASH_ON: "flash",
  FLASH_OFF: "flash-off",
  SWITCH: "camera-reverse",
  CLOSE: "close",
  RETRY: "trash",
  SAVE: "paper-plane",
});

export const Orientation = Object.freeze({
  PORTRAIT: "portrait",
  PORTRAIT_UPSIDE_DOWN: "portraitUpsideDown",
  LANDSCAPE_LEFT: "landscapeLeft",
  LANDSCAPE_RIGHT: "landscapeRight",
});

export const OrientationCameraXref = Object.freeze({
  1: Orientation.PORTRAIT,
  2: Orientation.PORTRAIT_UPSIDE_DOWN,
  3: Orientation.LANDSCAPE_LEFT,
  4: Orientation.LANDSCAPE_RIGHT,
});

export const ContentResizeMode = Object.freeze({
  COVER: "cover",
  CONTAIN: "contain",
});

export const Theme = Object.freeze({
  LIGHT: "LIGHT",
  DARK: "DARK",
});

export const PlaceHolderType = Object.freeze({
  COMMENT_ITEM: "COMMENT_ITEM",
  USER_CARD: "USER_CARD",
  USER_ITEM: "USER_ITEM",
  ALERT_ITEM: "ALERT_ITEM",
});
