import React from "react";
import { TestimonialsConfig } from "@utility/constants";
import Cards from "@components/homePageComponents/whatPeopleAreSayingSection/cards/Cards";

export default function CardsContainer() {
  return (
    <div className="w-full flex relative top-5 h-full">
      <Cards cards={TestimonialsConfig} />
    </div>
  );
}
