import React from "react";

export default function Card({
  card: { title, img, body, name, isVerified = false },
}) {
  return (
    <div
      className="rounded-md lg:h-full w-full h-44 mx-1 phoneBreakpoint:h-34 shadow-cardShadow z-20 flex flex-col phoneBreakpoint:mx-4 lg:mx-6 lg:w-1/3 py-3"
      style={{
        background: "#AC21CC",
        backgroundImage: "linear-gradient(40deg, #AC21CC 0%, #5106C7 84%)",
      }}
    >
      <CardHeader img={img} isVerified={isVerified} name={name} />
      <CardBody title={title} body={body} />
    </div>
  );
}

// Card Header Component
function CardHeader({ img, name, isVerified }) {
  return (
    <div className="flex flex-row w-full px-4 sm:px-4 pt-3">
      {img && (
        <div className="flex items-center justify-center ">
          <img src={img} className="h-12 w-12" alt="avatar" />
        </div>
      )}
      <div className="flex flex-col">
        <span className="text-white text-xl">{name}</span>
        {isVerified && (
          <div className="flex flex-row items-center">
            <span className="text-white font-bolder text-sm lg:text-md ">
              Verified
            </span>
            <img
              src="/images/verified-icon.png"
              className="h-4 w-4 mx-2"
              alt="verified"
            />
          </div>
        )}
      </div>
    </div>
  );
}
// Card Body Component
function CardBody({ title, body }) {
  return (
    <div className="w-full flex flex-col max-h-full h-full py-4 px-4 ">
      <span className="text-white text-lg font-semibold">{title}</span>
      <span className="text-white text-sm lg:text-base pt-2">{body}</span>
    </div>
  );
}
