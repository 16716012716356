import React from "react";
import Link from "next/link";
import DownloadTheAppButtons from "@components/buttons/DownloadTheAppButtons";

export default function Footer() {
  return (
    <>
      <footer
        className="flex flex-col lg:flex-row"
        style={{ backgroundColor: "#21252A" }}
      >
        <FooterLeftContentContainer />
        <FooterRightContentContainer />
      </footer>
      <FooterBottomBar />
    </>
  );
}
// Start Images //
function FooterPhoneImage() {
  return (
    <div className="w-full flex justify-center items-end">
      <img
        src="/images/mobile-footer-phone.webp"
        className="z-20 max-h-full"
        alt="mobile phone"
        height="450"
        width="450"
      />
    </div>
  );
}

function FooterGreyCircle() {
  return (
    <img
      src="/images/footer-grey-circle.png"
      className="h-[450px] absolute right-0 bottom-0"
      alt=""
      height="450"
      width="410"
    />
  );
}

function GreySquare() {
  return (
    <div className="absolute -left-10 md:left-10 lg:-left-10 -top-10 ">
      <img src="/images/headersquare.png " alt="" height="95" width="95" />
    </div>
  );
}

function PinkSparkle() {
  return (
    <div className="absolute left-10 md:top-5 phoneBreakpoint:left-40 lg:left-0">
      <img src="/images/pink-sparkle.png" alt="" height="50" width="50" />
    </div>
  );
}

// End Images //

// Start Content Container //
function FooterLeftContentContainer() {
  return (
    <div className=" lg:w-1/2 flex lg:h-full px-4 lg:py-4 md:justify-center lg:justify-start">
      <div className="lg:h-full items-center flex justify-center lg:justify-end">
        <div className="w-full lg:w-2/3 flex flex-col h-full py-16 justify-center items-center lg:items-start">
          <div className="flex flex-col z-10 relative">
            <GreySquare />
            <span className="text-white text-5xl z-10 text-center lg:text-left">
              Where will <span style={{ color: "#27AAE1" }}>you</span>
            </span>
            <span className="text-white text-5xl z-10 text-center lg:text-left">
              be for Snacktime today?
            </span>
          </div>
          <div>
            <p className="text-white text-lg pt-5 text-center lg:text-left">
              It`s Snacktime! You have seconds to share your moment. Available
              on the App Store.
            </p>
          </div>
          <div>
            <DownloadTheAppButtons size="large" />
          </div>
        </div>
      </div>
    </div>
  );
}
function FooterRightContentContainer() {
  return (
    <div className="flex w-full lg:w-1/2 relative lg:h-full justify-center">
      <PinkSparkle />
      <FooterPhoneImage />
      <FooterGreyCircle />
    </div>
  );
}

function FooterBottomBar() {
  const copyrightYear = new Date().getFullYear();
  return (
    <div className="flex w-full bg-black py-4 flex-col lg:flex-row ">
      <div className="flex-1 flex justify-center items-center order-1 lg:order-0 my-4 lg:my-0">
        <span className="text-white">© Snacktime {copyrightYear}</span>
      </div>
      <div className="flex-1 flex justify-center items-center my-3 lg:my-0">
        <img
          src="/images/Logo-white.png"
          height="80"
          width="200"
          alt="Snacktime"
        />
      </div>
      <div className="flex-1  flex justify-evenly items-center my-4 lg:my-0">
        <span className="text-white hover:underline cursor-pointer">
          <Link href="/terms">Terms of Service</Link>
        </span>
        <span className="text-white hover:underline cursor-pointer">
          <Link href="/privacy">Privacy</Link>
        </span>
        <span className="text-white hover:underline cursor-pointer">
          <a href={`mailto:${process.env.NEXT_PUBLIC_SNACKTIME_EMAIL}`}>
            Contact
          </a>
        </span>
      </div>
    </div>
  );
}
