import React from "react";
import AppStoreButton from "./AppStoreButton";
import GooglePlayStoreButton from "./GooglePlayStoreButton";

export default function Button({ size }) {
  return (
    <div className="flex items-between flex-col mt-4 justify-between h-[105px]">
      <AppStoreButton size={size} />
      <GooglePlayStoreButton size={size} />
    </div>
  );
}
