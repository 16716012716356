import React, { useState, useEffect, useRef } from "react";
import DownloadTheAppButtons from "@components/buttons/DownloadTheAppButtons";

const phoneImages = [
  "/webpPhoneShots/snacktimePortrait0.webp",
  "/webpPhoneShots/snacktimePortrait1.webp",
  "/webpPhoneShots/snacktimePortrait2.webp",
  "/webpPhoneShots/snacktimePortrait3.webp",
  "/webpPhoneShots/snacktimePortrait4.webp",
  "/webpPhoneShots/snacktimePortrait5.webp",
  "/webpPhoneShots/snacktimePortrait6.webp",
  "/webpPhoneShots/snacktimePortrait7.webp",
  "/webpPhoneShots/snacktimePortrait8.webp",
  "/webpPhoneShots/snacktimePortrait9.webp",
  "/webpPhoneShots/snacktimePortrait10.webp",
  "/webpPhoneShots/snacktimePortrait11.webp",
  "/webpPhoneShots/snacktimePortrait12.webp",
  "/webpPhoneShots/snacktimePortrait13.webp",
  "/webpPhoneShots/snacktimePortrait14.webp",
  "/webpPhoneShots/snacktimePortrait15.webp",
  "/webpPhoneShots/snacktimePortrait16.webp",
  "/webpPhoneShots/snacktimePortrait17.webp",
  "/webpPhoneShots/snacktimePortrait18.webp",
  "/webpPhoneShots/snacktimePortrait19.webp",
  "/webpPhoneShots/snacktimePortrait20.webp",
  "/webpPhoneShots/snacktimePortrait21.webp",
  "/webpPhoneShots/snacktimePortrait22.webp",
  "/webpPhoneShots/snacktimePortrait23.webp",
  "/webpPhoneShots/snacktimePortrait24.webp",
  "/webpPhoneShots/snacktimePortrait25.webp",
  "/webpPhoneShots/snacktimePortrait26.webp",
  "/webpPhoneShots/snacktimePortrait27.webp",
  "/webpPhoneShots/snacktimePortrait28.webp",
  "/webpPhoneShots/snacktimePortrait29.webp",
  "/webpPhoneShots/snacktimePortrait30.webp",
  "/webpPhoneShots/snacktimePortrait31.webp",
  "/webpPhoneShots/snacktimePortrait32.webp",
  "/webpPhoneShots/snacktimePortrait33.webp",
  "/webpPhoneShots/snacktimePortrait34.webp",
  "/webpPhoneShots/snacktimePortrait35.webp",
  "/webpPhoneShots/snacktimePortrait36.webp",
  "/webpPhoneShots/snacktimePortrait37.webp",
  "/webpPhoneShots/snacktimePortrait38.webp",
  "/webpPhoneShots/snacktimePortrait39.webp",
  "/webpPhoneShots/snacktimePortrait40.webp",
  "/webpPhoneShots/snacktimePortrait41.webp",
  "/webpPhoneShots/snacktimePortrait42.webp",
  "/webpPhoneShots/snacktimePortrait43.webp",
  "/webpPhoneShots/snacktimePortrait44.webp",
  "/webpPhoneShots/snacktimePortrait45.webp",
  "/webpPhoneShots/snacktimePortrait46.webp",
  "/webpPhoneShots/snacktimePortrait47.webp",
  "/webpPhoneShots/snacktimePortrait48.webp",
  "/webpPhoneShots/snacktimePortrait49.webp",
  "/webpPhoneShots/snacktimePortrait50.webp",
  "/webpPhoneShots/snacktimePortrait51.webp",
  "/webpPhoneShots/snacktimePortrait52.webp",
  "/webpPhoneShots/snacktimePortrait53.webp",
  "/webpPhoneShots/snacktimePortrait54.webp",
  "/webpPhoneShots/snacktimePortrait55.webp",
  "/webpPhoneShots/snacktimePortrait56.webp",
  "/webpPhoneShots/snacktimePortrait57.webp",
];

export default function Section() {
  const [snacktimePortraitIndex, setSnacktimePortraitIndex] = useState(0);
  const [phoneImagesLoading, setPhoneImagesLoading] = useState(false);
  const [phoneImagesLoaded, setPhoneImagesLoaded] = useState(false);
  const imageTimer = useRef(null);

  const cacheImages = async (srcArray) => {
    const promises = await srcArray.map(
      (src) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onLoad = resolve();
          img.onError = reject();
        })
    );
    return Promise.all(promises);
  };

  useEffect(() => {
    if (phoneImagesLoaded === false && phoneImagesLoading === false) {
      setPhoneImagesLoading(true);
      cacheImages(phoneImages).then(() => {
        setPhoneImagesLoading(false);
        setPhoneImagesLoaded(true);
      });
    }
  }, [phoneImagesLoaded, phoneImagesLoading]);

  useEffect(() => {
    imageTimer.current = setTimeout(() => {
      if (phoneImagesLoaded) {
        setSnacktimePortraitIndex((currentIndex) => {
          if (currentIndex + 1 >= phoneImages.length) {
            return 0;
          }
          return currentIndex + 1;
        });
      }
    }, [2000]);
    return () => clearTimeout(imageTimer.current);
  }, [phoneImagesLoaded, snacktimePortraitIndex]);

  const renderScreenShotsImage = () => (
    <img
      className="mx-auto z-10 drop-shadow-md w-[325px] h-[610px]"
      alt="phone screen shots"
      height="610"
      width="325"
      src={phoneImages[snacktimePortraitIndex]}
    />
  );

  return (
    <section className="flex justify-center items-center pb-6 lg:py-32 lg:pb-0 px-4 mt-14 mb-32">
      <div className="flex w-full max-w-1200 flex-col items-center lg:flex-row">
        <div className="flex w-full lg:w-1/2 relative px-4 mb-2 lg:mb-0 ">
          <PurpleCircle />
          <PurpleSparkle />
          <PlayButton />
          {renderScreenShotsImage()}
        </div>
        <div className="flex w-full lg:w-1/2 flex-col items-center justify-center lg:items-start px-4">
          <div className="lg:max-w-500 py-6 text-center lg:text-left w-full lg:w-3/4">
            <span className="text-black text-4xl lg:text-6xl text-center">
              Snacktime is life unstaged
            </span>
          </div>
          <div className="py-4">
            <p className="text-lg lg:text-xl text-center lg:text-left">
              When the clock runs out, Snacktime is over and so is your streak.
              How many days will your streak be?
            </p>
          </div>
          <div>
            <DownloadTheAppButtons size="large" />
          </div>
        </div>
      </div>
    </section>
  );
}

function PurpleCircle() {
  return (
    <div className="absolute flex justify-center h-full w-full items-center lg:w-3/4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
      <img src="/images/phone-background.png" alt="" height="540" width="540" />
    </div>
  );
}

function PlayButton() {
  return (
    <div className="absolute right-20 bottom-20">
      <img src="/images/play-button.png" width="29" height="32" alt="" />
    </div>
  );
}

function PurpleSparkle() {
  return (
    <div className="absolute -left-1 md:left-20 lg:left-0 top-0">
      <img src="/images/purple-sparkle.png" height="68" width="68" alt="" />
    </div>
  );
}
